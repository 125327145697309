import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
// @ts-ignore
import Pdf from "../assets/cv.pdf";
// @ts-ignore
import CrossFigaroJPG from "../assets/crossFigaro2023.JPG";
// @ts-ignore
import MP2024JPG from "../assets/mp2024.JPG";
// @ts-ignore
import PSGClubTourJPG from "../assets/psgCLubTour.JPG";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import GroupsIcon from "@mui/icons-material/Groups";
import AnimatedNumber from "react-animated-number";

const Home = () => {
  return (
    <Box id="section">
      <Box sx={{ backgroundColor: "#f1eae1", textAlign: "center" }}>
        <Box>
          <Typography
            color="black"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Mon expertise
          </Typography>
          <Typography
            sx={{
              marginTop: "20px",
              fontSize: {
                lg: 20,
                md: 20,
                sm: 20,
                xs: 20,
              },
            }}
          >
            L'événementiel au service du sport
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
            marginTop: "50px",
          }}
        >
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
              }}
            >
              <MenuBookIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Gestion de projets
                </Typography>
                <Typography>
                  Dotée d'un sens profond de l'organisation et d'une proactivité
                  certaine, j'adore contribuer à la gestion d'un projet pour en
                  contempler la réalisation du brief jusqu'à sa livraison.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <LightbulbIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Atelier
                </Typography>
                <Typography>
                  Créative et force de proposition, j'échange avec des étudiants
                  et des bénévoles pour proposer des solutions innovantes et
                  immersives sur les événements sportifs.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <GroupsIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Logistique
                </Typography>
                <Typography>
                  Energique et débrouillarde, la logistique est une des
                  prestations que je réalise fréquemment. Elle me correspond
                  bien car elle me permet d'être en mouvement perpétuel et de
                  trouver des solutions inédites face à des imprévus.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
            marginTop: "50px",
          }}
        >
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
              }}
            >
              <MenuBookIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Hospitalités - VIP
                </Typography>
                <Typography>
                  Mon sens du contact et mon sens du détail me permettent de
                  nouer aisément des liens avec les invités et de répondre au
                  mieux à leurs attentes avec toute la bienveillance dont je
                  dispose.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <LightbulbIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Tour Leader - conciergerie
                </Typography>
                <Typography>
                  Formée initialement à l'événementiel à travers l'hôtessariat,
                  l'accueil de public n'a plus de secrets pour moi. Accompagner
                  et conseiller des touristes est un plaisir.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <GroupsIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Gestion d'équipes / bénévoles
                </Typography>
                <Typography>
                  Ces dernières années, j'ai développé mes compétences en
                  management d'équipe : hôtesses, bénévoles, étudiants. Mon sens
                  des responsabilités et ma pédagogie me permettent de gérer un
                  groupe.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Button
          sx={{
            backgroundColor: "white",
            color: "#CDAF8C",
            marginTop: "50px",
            marginBottom: "50px",
            ":hover": {
              bgcolor: "black",
              color: "white",
            },
          }}
          href={Pdf}
          target="_blank"
        >
          Télécharger mon CV
        </Button>
        {/* <Stripe /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#E8D6C1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Quelques chiffres clés
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            marginBottom: "50px",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Chip
            sx={{ height: "150px" }}
            label={
              <Box sx={{ width: "200px", textWrap: "balance" }}>
                <Typography variant="h4" textAlign="center" color="black">
                  <AnimatedNumber
                    stepPrecision={0}
                    value="21"
                    duration="1800"
                  />
                </Typography>
                <Typography
                  style={{ whiteSpace: "break-spaces" }}
                  fontWeight="bold"
                  textAlign="center"
                  color="black"
                >
                  clients dans l'événementiel sportif.
                </Typography>
              </Box>
            }
            variant="outlined"
          />

          <Chip
            sx={{ height: "150px" }}
            label={
              <Box sx={{ width: "200px", textWrap: "balance" }}>
                <Typography variant="h4" textAlign="center" color="black">
                  <AnimatedNumber
                    stepPrecision={0}
                    value="32"
                    duration="1800"
                  />
                </Typography>
                <Typography
                  style={{ whiteSpace: "break-spaces" }}
                  fontWeight="bold"
                  textAlign="center"
                  color="black"
                >
                  semaines d'intervention sur des événements en 2023.
                </Typography>
              </Box>
            }
            variant="outlined"
          />

          <Chip
            sx={{ height: "150px" }}
            label={
              <Box sx={{ width: "200px", textWrap: "balance" }}>
                <Typography variant="h4" textAlign="center" color="black">
                  <AnimatedNumber
                    stepPrecision={0}
                    value="124"
                    duration="1800"
                  />
                </Typography>
                <Typography
                  style={{ whiteSpace: "break-spaces" }}
                  fontWeight="bold"
                  textAlign="center"
                  color="black"
                >
                  litres de café noisette.
                </Typography>
              </Box>
            }
            variant="outlined"
          />

          <Chip
            sx={{ height: "150px" }}
            label={
              <Box sx={{ width: "200px", textWrap: "balance" }}>
                <Typography variant="h4" textAlign="center" color="black">
                  <AnimatedNumber
                    stepPrecision={0}
                    value="14"
                    duration="1800"
                  />
                </Typography>
                <Typography
                  style={{ whiteSpace: "break-spaces" }}
                  fontWeight="bold"
                  textAlign="center"
                  color="black"
                >
                  contrats refusés faute de disponibilités.
                </Typography>
              </Box>
            }
            variant="outlined"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1eae1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Mes derniers projets
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            marginBottom: "50px",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                width: "313px",
                height: { xs: "190px", sm: "200px" },
                marginBottom: "20px",
                borderRadius: "10px",
              }}
              component="img"
              src={PSGClubTourJPG}
            />
            <Typography
              sx={{
                marginBottom: { xs: "10px", md: "0px" },
              }}
              fontWeight="bold"
              textAlign="center"
              color="black"
            >
              PSG Club tour 2024
            </Typography>
            <Typography
              sx={{
                marginBottom: { xs: "30px", md: "0px" },
              }}
              fontWeight="bold"
              fontStyle="italic"
              textAlign="center"
              color="black"
              variant="body2"
            >
              Responsable hôtes & logistique événementielle
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "313px",
                height: { xs: "190px", sm: "200px" },
                borderRadius: "10px",
                marginTop: { xs: "10px", md: "0px" },
                marginBottom: "20px",
              }}
              component="img"
              src={MP2024JPG}
            />
            <Typography
              sx={{
                marginBottom: { xs: "10px", md: "0px" },
              }}
              fontWeight="bold"
              textAlign="center"
              color="black"
            >
              Marathon de Paris 2024
            </Typography>
            <Typography
              sx={{
                marginBottom: { xs: "30px", md: "0px" },
              }}
              fontWeight="bold"
              fontStyle="italic"
              textAlign="center"
              color="black"
            >
              Logistique & signalétique
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "313px",
                height: { xs: "190px", sm: "200px" },
                borderRadius: "10px",
                marginTop: { xs: "10px", md: "0px" },
                marginBottom: "20px",
              }}
              component="img"
              src={CrossFigaroJPG}
            />
            <Typography
              sx={{
                marginBottom: { xs: "10px", md: "0px" },
              }}
              fontWeight="bold"
              textAlign="center"
              color="black"
            >
              Cross du Figaro 2023
            </Typography>
            <Typography
              sx={{
                marginBottom: { xs: "30px", md: "0px" },
              }}
              fontWeight="bold"
              fontStyle="italic"
              textAlign="center"
              color="black"
            >
              Responsable bénévoles
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
