import * as React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
// @ts-ignore
import EbookPDFApresJO from "../assets/apresJO.pdf";
// @ts-ignore
import EbookPDFPimperCandidature from "../assets/pimperCandidature.pdf";
import { Ebook } from "../types";

interface Props {
  onClose: () => void;
  ebook: Ebook;
  setAmount: (amountWithProm: number) => void;
}

export const CheckoutForm = ({ onClose, ebook, setAmount }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSuccessContent, setOpenSuccessContent] = useState(false);
  const [email, setEmail] = useState("");
  // const [codePromo, setCodePromo] = useState("");
  const [amountToPay, setAmountToPay] = useState(ebook.amount);
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    // if (codePromo === "EBOOK10") {
    //   setAmount(450);
    //   setAmountToPay(450);
    // } else {
    setAmount(ebook.amount);
    setAmountToPay(ebook.amount);
    //}
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (stripe && elements) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }

      setIsLoading(true);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_SERVER_PRD_URL}/stripe/paiement`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              ebookType: ebook.type,
              amount: amountToPay,
            }),
          }
        );
        const { clientSecret } = await res.json();

        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        if (paymentIntent) {
          const result = await stripe.confirmPayment({
            elements,
            clientSecret: clientSecret,
            confirmParams: {
              return_url: "https://cassandrethezenas.com/",
            },
            redirect: "if_required",
          });
          setIsLoading(false);

          if (result.error) setOpenSnackBar(true);
          else {
            setOpenSuccessContent(true);
            setTimeout(() => {
              if (ref.current) ref.current.click();
            }, 300);
          }
        } else {
          setIsLoading(false);
          setOpenSnackBar(true);
        }
      } catch {
        setIsLoading(false);
        setOpenSnackBar(true);
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "400", marginTop: "20px", padding: "10px" }}
      >
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Le paiment a échoué, veuilez réessayer svp.
          </Alert>
        </Snackbar>

        {openSuccessContent ? (
          <Box display="flex" flexDirection="column">
            <Typography
              color="black"
              sx={{
                fontSize: {
                  lg: 20,
                  md: 20,
                  sm: 20,
                  xs: 20,
                },
              }}
            >
              Le paiement a réussi, vous pouvez télécharger le ebook en cliquant
              sur le bouton ci-dessous.
            </Typography>
            <Typography
              color="black"
              sx={{
                marginTop: "20px",
                fontSize: {
                  lg: 20,
                  md: 20,
                  sm: 20,
                  xs: 20,
                },
                color: "#CDAF8C",
              }}
            >
              Merci pour votre commande et à très bientôt !
            </Typography>
            <Button
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                backgroundColor: "black",
              }}
              variant="contained"
              href={
                ebook.type === "Apres-JO"
                  ? EbookPDFApresJO
                  : EbookPDFPimperCandidature
              }
              target="_blank"
              ref={ref}
            >
              Télécharger
            </Button>
          </Box>
        ) : (
          <>
            {isLoading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <Box display={"flex"} sx={{ gap: "5px" }}>
              <TextField
                helperText="Merci de renseigner votre email"
                label="Email"
                required
                type="email"
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* <TextField
                helperText={
                  codePromo === "EBOOK10" && "une promotion va être appliquée"
                }
                label="Code promo"
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setCodePromo(e.target.value)}
              /> */}
            </Box>

            <PaymentElement />
            <Box display="flex" justifyContent="space-between">
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "#CDAF8C",
                  marginTop: "20px",
                  marginBottom: "10px",
                  ":hover": {
                    bgcolor: "black",
                    color: "white",
                  },
                }}
                disabled={!stripe || isLoading}
                type="submit"
              >
                Commander
              </Button>
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "#CDAF8C",
                  marginTop: "20px",
                  marginBottom: "10px",
                  ":hover": {
                    bgcolor: "black",
                    color: "white",
                  },
                }}
                onClick={onClose}
              >
                Fermer
              </Button>
            </Box>
          </>
        )}
      </form>
    </>
  );
};
