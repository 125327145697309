import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// @ts-ignore
import Pdf from "../assets/portfolio.pdf";
// @ts-ignore
import MeJPG from "../assets/me.jpg";

// @ts-ignore
import FavoriteIcon from "@mui/icons-material/Favorite";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Button } from "@mui/material";

const MyProfile = () => {
  return (
    <Box
      id="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8D6C1",
        marginTop: "-7px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "80%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "375px" },
              height: { xs: "100", md: "435px" },
              marginTop: "30px",
            }}
            component="img"
            src={MeJPG}
          />
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Cassandre THEZENAS
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
              color: "#815765",
            }}
          >
            Freelance | Evénementiel sportif
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
            }}
          >
            <a
              style={{ color: "black" }}
              href="mailto:cassandrethezenas@gmail.com"
              target="_top"
            >
              cassandrethezenas@gmail.com
            </a>
          </Typography>

          <Typography
            sx={{
              textAlign: "center",
              color: "#815765",
              fontSize: 20,
            }}
          >
            27 ans | Paris
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "50px",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "50px",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FavoriteIcon />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "10px",
                fontSize: 20,
              }}
            >
              Passionnée
            </Typography>
            <Typography sx={{ fontSize: 15, textAlign: "center" }}>
              Initialement formée aux langues étrangères et aux stratégies
              évènementielles grâce à un MASTER à l'ISCOM, je me suis tournée
              dans un premier temps vers les événements B2B. Ma passion et mon
              engouement pour les événements sportifs m'ont vite rattrapé car
              après quelques mois à travailler sur un salon international au
              service de l'environnement, j'ai entamé une transition à 90° vers
              les manifestations sportives. Au menu, service civique dans une
              fédération, bénévolats et missions d'hôtesse dans des grands
              stades.
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
            marginBottom: "50px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TrendingUpIcon />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "10px",
                fontSize: {
                  lg: 22,
                  md: 22,
                  sm: 20,
                  xs: 20,
                },
              }}
            >
              Ambitieuse
            </Typography>
            <Typography sx={{ fontSize: 15, textAlign: "center" }}>
              Après plusieurs mois en agence sur des événements comme
              Roland-Garros ou les 24h du mans, j'ai décidé de créer ma
              micro-entreprise. Ainsi, depuis septembre 2022, j'ai l'occasion de
              participer à l'organisation de différents événements comme le
              Marathon de Paris, l'Urgent Run, les NBA Paris Games ou encore les
              24H Moto. Mes prestations sont variées puisque je relève tous les
              challenges qui s'offrent à moi : logistique, gestion de bénévoles,
              conciergerie, transports, hospitalités,.. Dans les prochains mois,
              j'ai prévu d'intervenir principalement sur des événements à
              l'étranger. Stay tuned !
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
            marginBottom: "50px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VolunteerActivismIcon />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                marginBottom: "10px",
                fontSize: {
                  lg: 22,
                  md: 22,
                  sm: 20,
                  xs: 20,
                },
              }}
            >
              Résiliente
            </Typography>
            <Typography sx={{ fontSize: 14.5, textAlign: "center" }}>
              Avant de prospérer comme freelance, j'ai connu de nombreux
              déboires : chômage, licenciements, remises en question. Ces
              épreuves m'ont permis d'être celle que je suis aujourd'hui. A
              travers mes réseaux sociaux, je partage avec plaisir tous les
              conseils et astuces qui m'ont aidé à arriver là où j'en suis
              aujourd'hui.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Button
        sx={{
          alignSelf: "center",
          backgroundColor: "#CDAF8C",
          color: "#000",
          ":hover": {
            bgcolor: "#CDAF8C",
            color: "black",
          },
          marginBottom: "30px",
        }}
        href={Pdf}
        target="_blank"
      >
        Portfolio
      </Button>
    </Box>
  );
};

export default MyProfile;
