import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm";
import { Ebook } from "../types";

const publicKey = process.env.REACT_APP_PRD_STRIPE_PK;
const stripeTestPromise = loadStripe(publicKey!);

interface Props {
  onClose: () => void;
  ebook: Ebook;
  setAmount: (amountWithProm: number) => void;
}

const Stripe = ({ onClose, ebook, setAmount }: Props) => {
  return (
    <Elements
      stripe={stripeTestPromise}
      options={{
        mode: "payment",
        amount: 1099,
        currency: "eur",
      }}
    >
      <CheckoutForm onClose={onClose} ebook={ebook} setAmount={setAmount} />
    </Elements>
  );
};

export default Stripe;
