import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Clients from "./components/clients";
import Header from "./components/header";
import Contact from "./components/contact";
import Home from "./components/home";
import Juniors from "./components/juniors";
import MyProfile from "./components/myprofile";
import Ebooks from "./components/ebooks";

function AppRouter() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/ebooks" element={<Ebooks />} />
        <Route path="/juniors" element={<Juniors />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
