import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
// @ts-ignore
import VignettePimperCandidaturePNG from "../assets/pimperCandidature.png";
// @ts-ignore
import VignetteApresJOPNG from "../assets/vignetteApresJO.png";
// @ts-ignore
import VignetteEventPNG from "../assets/vignetteEvent.png";
// @ts-ignore
import SurvolVignetteApresJOPNG from "../assets/survolApresJO.png";
// @ts-ignore
import SurvolVignettePimperCandidaturePNG from "../assets/survolPimperCandidature.png";
import PaiementModal from "./paiementModal";
import { useState } from "react";
import { Ebook } from "../types";
import FreeModal from "./freeModal";
import EbookCard from "./ebookCard";

const Ebooks = () => {
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [ebook, setEbook] = useState<Ebook>();

  return (
    <Box id="section" sx={{ backgroundColor: "#f1eae1", textAlign: "center" }}>
      <Typography color="black" variant="h4">
        E-books
      </Typography>
      <Typography color="black" sx={{ mt: 5 }}>
        Ci-dessous, tu trouveras les offres d'emplois, les astuces de
        communications et les conseils qui te permettront d'intégrer l'industrie
        de l'événementiel sportif.
      </Typography>
      {openModal && ebook && (
        <PaiementModal
          title={modalTitle}
          description={modalDescription}
          open={openModal}
          onClose={() => setOpenModal(false)}
          ebook={ebook}
        />
      )}
      {openFreeModal && ebook && (
        <FreeModal
          title={modalTitle}
          open={openFreeModal}
          onClose={() => setOpenFreeModal(false)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-around" },
          marginTop: "50px",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard
            image={VignettePimperCandidaturePNG}
            descriptionImage={SurvolVignettePimperCandidaturePNG}
          />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle("Commande - E-book pimper sa candidature");
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setEbook({ amount: 1000, type: "Pimper-candidature" });
            }}
          >
            Commander
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard
            image={VignetteApresJOPNG}
            descriptionImage={SurvolVignetteApresJOPNG}
          />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle(
                "Commande - E-book comment préparer l'après Jeux Olympiques et Paralympiques"
              );
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setEbook({ amount: 1000, type: "Apres-JO" });
            }}
          >
            Commander
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "300px",
              height: "300px",
              borderRadius: "15px 15px 15px 15px",
            }}
            component="img"
            src={VignetteEventPNG}
          />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle(
                "Commande - Les métiers de l'événementiel  (gratuit)"
              );
              setModalDescription(" Paiement en CB, virement, Stripe.");
              setOpenFreeModal(true);
              setEbook({ amount: 0, type: "JO" });
            }}
          >
            Télécharger
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Ebooks;
